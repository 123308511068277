/**
 * Gutenberg Blocks
 *
 * All blocks related JavaScript files should be imported here.
 * You can create a new block folder in this dir and include code
 * for that block here as well.
 *
 * All blocks should be included here since this is the file that
 * Webpack is compiling as the input file.
 */
import SlimSelect from 'slim-select';
import Slick from 'slick-carousel';

import autoAjaxSearch from './partials/auto-ajax-search';
autoAjaxSearch(jQuery)

import singleAutoSlider from './partials/single-auto-slider';
singleAutoSlider(jQuery,Slick)

import heroSearchBox from './partials/hero-search-box';
heroSearchBox(jQuery,SlimSelect)

import tailwindHeader from './partials/tailwind-header';
tailwindHeader(jQuery)