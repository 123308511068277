export default ($) => {
  const prev =
    '<svg width="30px" height="50px" viewBox="-19.04 0 75.803 75.803" xmlns="http://www.w3.org/2000/svg"><g id="Group_64" data-name="Group 64" transform="translate(-624.082 -383.588)"><path id="Path_56" data-name="Path 56" d="M660.313,383.588a1.5,1.5,0,0,1,1.06,2.561l-33.556,33.56a2.528,2.528,0,0,0,0,3.564l33.556,33.558a1.5,1.5,0,0,1-2.121,2.121L625.7,425.394a5.527,5.527,0,0,1,0-7.807l33.556-33.559A1.5,1.5,0,0,1,660.313,383.588Z" fill="#0c2c67"/></g></svg>';
  const next =
    '<svg width="30px" height="50px" viewBox="-19.04 0 75.804 75.804" xmlns="http://www.w3.org/2000/svg"><g id="Group_65" data-name="Group 65" transform="translate(-831.568 -384.448)"><path id="Path_57" data-name="Path 57" d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z" fill="#0c2c67" /></g></svg>';

  if ($(".slider-for")) {
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      adaptiveHeight: true,
      prevArrow: `<div class="prev">${prev}</div>`,
      nextArrow: `<div class="next">${next}</div>`,
    });

    document
      .querySelectorAll(".slider-thumb.thumbnail img")
      .forEach((button, currentIndex) => {
        button.addEventListener("click", () => {
          $(".slider-for").slick("slickGoTo", currentIndex);
        });
      });
  }

  if ($(".slider-tailwind")) {
    $(".slider-tailwind").slick({
      centerPadding: "60px",
      slidesToShow: 2,
      prevArrow: `<div class="prev">${prev}</div>`,
      nextArrow: `<div class="next">${next}</div>`,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    });

    document
      .querySelectorAll(".slider-thumb.thumbnail img")
      .forEach((button, currentIndex) => {
        button.addEventListener("click", () => {
          $(".slider-for").slick("slickGoTo", currentIndex);
        });
      });
  }

  if (document.querySelector(".show-vehicles .item-slider")) {
    $(".item-slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: true,
      fade: false,
      adaptiveHeight: true,
      prevArrow: `<div class="prev">${prev}</div>`,
      nextArrow: `<div class="next">${next}</div>`,
    });
  }
};
