export default ($, SlimSelect) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var needToGetModels = false;
    var isFirstTimeOnLoaded = true;
    const prev = '<svg width="30px" height="50px" viewBox="-19.04 0 75.803 75.803" xmlns="http://www.w3.org/2000/svg"><g id="Group_64" data-name="Group 64" transform="translate(-624.082 -383.588)"><path id="Path_56" data-name="Path 56" d="M660.313,383.588a1.5,1.5,0,0,1,1.06,2.561l-33.556,33.56a2.528,2.528,0,0,0,0,3.564l33.556,33.558a1.5,1.5,0,0,1-2.121,2.121L625.7,425.394a5.527,5.527,0,0,1,0-7.807l33.556-33.559A1.5,1.5,0,0,1,660.313,383.588Z" fill="#0c2c67"/></g></svg>';
    const next = '<svg width="30px" height="50px" viewBox="-19.04 0 75.804 75.804" xmlns="http://www.w3.org/2000/svg"><g id="Group_65" data-name="Group 65" transform="translate(-831.568 -384.448)"><path id="Path_57" data-name="Path 57" d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z" fill="#0c2c67" /></g></svg>';
    const loader = '<div class="loader-wrap"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>';


    if(document.querySelector('#all_makes') === null){
        return;
    }

    const allMakes = new SlimSelect({
        text: 'text',
        select: `#all_makes`,
        hideSelectedOption: true,
        settings: {
            placeholderText: 'All Makes',
        },
        events: {
            afterChange: (newVal) => {
                if (needToGetModels) {
                    get_models_by_id(newVal[0].value)
                }
                needToGetModels = true;
            }
        }
    })

    const allModels = new SlimSelect({
        text: 'text',
        select: `#all_models`,
        hideSelectedOption: true,
        settings: {
            placeholderText: 'All Models'
        }
    })

    var years = [{ text: 'From', placeholder: true, value: 0 }];
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
        years.push({ text: i, value: i })
    }

    const from = new SlimSelect({
        text: 'text',
        select: `#select-from`,
        hideSelectedOption: true,
        settings: {
            showSearch: false
        },
        data: years
    })

    years[0].text = 'To'

    const to = new SlimSelect({
        text: 'text',
        select: `#select-to`,
        hideSelectedOption: true,
        settings: {
            showSearch: false
        },
        data: years
    })

    $(document).ready(() => {
        var data = {
            action: 'get-models',
        };
        $.ajax({
            type: "POST",
            url: global_variables.admin_ajax,
            data: data,

            success: function (response) {
                if (response.success) {
                    allMakes.setData(response.data)
                    if(urlParams.has('v-makes')){
                        allMakes.setSelected(parseInt(urlParams.get('v-makes')))
                        get_models_by_id(parseInt(urlParams.get('v-makes')))
                    }
                    if(document.querySelector('#all_makes').hasAttribute('select')){
                        let makes_id = parseInt(document.querySelector('#all_makes').getAttribute('select'))
                        allMakes.setSelected(makes_id)
                        get_models_by_id(makes_id)
                    }
                } else {
                    console.error(response)
                }
            },
            error: function (response) {
                console.error(response)
            }
        });

        if(urlParams.has('to')){
            from.setSelected(parseInt(urlParams.get('from')))
        }
        if(urlParams.has('to')){
            to.setSelected(parseInt(urlParams.get('to')))
        }

        // on load get vehicles
        if(document.querySelector('.page-vehicles .auto-grid-wrap') || document.querySelector('.tax-models .auto-grid-wrap')){
            get_vehicles()
        }
    })

    function get_models_by_id(selected) {
        var data = {
            action: 'get-child-models',
            parent_id: selected
        };

        $.ajax({
            type: "POST",
            url: global_variables.admin_ajax,
            data: data,
            success: function (response) {
                if (response.success) {
                    allModels.setData(response.data)
                    if(isFirstTimeOnLoaded){
                        if(urlParams.has('v-makes') && urlParams.has('models') && urlParams.get('v-makes') != 0){
                            allModels.setSelected(parseInt(urlParams.get('models')))
                        }
                        if(document.querySelector('#all_models').hasAttribute('select')){
                            let model_id = parseInt(document.querySelector('#all_models').getAttribute('select'))
                            allModels.setSelected(model_id)
                        }
                        isFirstTimeOnLoaded = false;
                    }
                } else {
                    console.error(response)
                }
            },
            error: function (response) {
                console.error(response)
            }
        });
    }

    $('#load-more').on('click', function(){
        get_vehicles($('#load-more').data('page'))
    })

    function get_vehicles(page = 1) {
        $('.auto-grid-wrap').append(loader)
        var data = {
            action: 'ajax_get_vehicles',
            page: page
        };

        if(urlParams.has('v-makes') && urlParams.get('v-makes') != 0){
            data['v-makes'] = urlParams.get('v-makes')
        } else if(document.querySelector('.v-makes').hasAttribute('select')){
            let makes_id = parseInt(document.querySelector('.v-makes').getAttribute('select'))
            data['v-makes'] = makes_id
        }

        if(urlParams.has('models') && urlParams.get('models') != 0){
            data['models'] = urlParams.get('models')
        } else if(document.querySelector('.models').hasAttribute('select')){
            let model_id = parseInt(document.querySelector('.models').getAttribute('select'))
            data['models'] = model_id
        }

        if(urlParams.has('from') && urlParams.get('from') != 0){
            data['from'] = urlParams.get('from')
        }

        if(urlParams.has('to') && urlParams.get('to') != 0){
            data['to'] = urlParams.get('to')
        }

        $.ajax({
            type: "POST",
            url: global_variables.admin_ajax,
            data: data,
            success: function (response) {
                if (response.success) {
                    $('.auto-grid-wrap').append(response.data)
                    if ($('.item-slider')) {
                        $(`.item-slider.page-${page}`).slick({
                            arrows: true,
                            fade: false,
                            prevArrow: `<div type='button' class='slick-prev'>${prev}</div>`,
                            nextArrow: `<div type='button' class='slick-next'>${next}</div>`
                        });
                    }
                    $('#load-more').data('page',page+1)
                } else {
                    console.error(response)
                }
                $('.loader-wrap').remove()
            },
            error: function (response) {
                $('.loader-wrap').remove()
                console.error(response)
            }
        });
    }

}