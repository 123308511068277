export default ($) => {
    if (document.querySelector('#custom-auto-search')) {
        document.querySelector('#custom-auto-search').addEventListener("submit", function (event) {
            event.preventDefault();
            $('#bar_message').text('')
            document.querySelector('.progress-bar-wrapper').style.display = 'block';
            $(".progress-bar-wrapper").append('<div id="progressBar"><div class="bar"></div></div>');
            progress(60, 60, $('#progressBar'));
            var data = {
                action: 'get_single_auto',
                vin: document.querySelector('#custom-auto-search input').value
            };

            $.ajax({
                type: "POST",
                url: global_variables.admin_ajax,
                data: data,

                success: function (data) {
                    if (data.success) {
                        window.location.replace(data.data);
                        //console.log(data)
                    } else {
                        $('#progressBar').remove()
                        $('#bar_message').append('Sorry, but nothing matched your search terms. Please try again with some different keywords.')
                    }
                },
                error: function (data) {
                    $('#progressBar').remove()
                    $('#bar_message').append('Sorry, but nothing matched your search terms. Please try again with some different keywords.')
                }
            });
        });

        function progress(timeleft, timetotal, $element) {
            var progressBarWidth = timeleft * $element.width() / timetotal;
            $element.find('div').animate({
                width: progressBarWidth
            }, 500).html(Math.floor(timeleft / 60) + ":" + timeleft % 60);
            if (timeleft > 0) {
                setTimeout(function () {
                    progress(timeleft - 1, timetotal, $element);
                }, 1000);
            }
        };

        progress(60, 60, $('#progressBar'));
    }

}